import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button, Dropdown, DropdownMenu, DropdownItem, Icon, Radio, DatePicker, Cascader, Input, MessageBox, Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './until/rem'
import '@/common/css/font.css'
// import 'lib-flexible'
import './assets/css/datePicker.css'
// import Mui from 'vue-awesome-mui'
import Quagga from 'quagga'

Vue.config.productionTip = false
Vue.use(Button)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Icon)
Vue.use(Radio)
Vue.use(DatePicker)
Vue.use(Cascader)
Vue.use(Input)
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message
// Vue.use(Mui)
Vue.prototype.$Quagga = Quagga

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
