<template>
  <div id="app">
    <headers></headers>
    <router-view/>
  </div>
</template>
<script>
import headers from './components/headers.vue'
export default {
  components: { headers }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #F7F8FA;
}
p{
  padding: 0;
  margin: 0;
}
body{
  padding: 0;
  margin: 0;
}
</style>
