// 域名统一管理

const base = {
  // 本地
  // url: 'http://192.168.31.212:9071/api'
  // 测试
  // url: 'http://120.27.16.129:9071/api'
  // 正式环境
  url: 'https://cashier.decades-official.com/api'

}
export default base
