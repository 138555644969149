<template>
  <div>
    <div class="header">
      <div class="nav">
        <p class="nav-list" @click="salesSlip()">销售单</p>
        <p class="nav-list" :class="{'cashierActivity':navIndex == 1}" @click="cashier(1)">日结报表</p>
        <!-- <p style="flex:1;"></p> -->
        <p class="nav-list" :class="{'cashierActivity':navIndex == 0}" @click="cashier(0)">收银</p>
      </div>
      <img src="../assets/images/logo2.png" class="header-photo" alt="">
      <p class="header-name">{{detail.nickName}}</p>
      <p class="loginOut" @click="loginOut()">退出登录</p>
    </div>
  </div>
</template>

<script>
import { findByUserId } from '../until/user.js'
export default {
  props: {},
  components: {},
  data () {
    return {
      navIndex: 0,
      detail: {}
    }
  },
  computed: {},
  created () {},
  mounted () {
    var navIndex = sessionStorage.getItem('navIndex')
    console.log(navIndex)
    if (navIndex != null) {
      this.navIndex = navIndex
    }
    return new Promise((resolve, reject) => {
      findByUserId()
        .then(response => {
          if (response.data.code === 200) {
            // console.log('进入')
            this.detail = response.data.data
            sessionStorage.setItem('userDetail', JSON.stringify(response.data.data))
          } else {
            this.$message({
              message: response.data.msg,
              type: 'warning'
            })
            return
          }
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  methods: {
    salesSlip () {
      // window.open('http://192.168.31.170:8013/dashboard')
      window.open('https://admin.decades-official.com')
    },
    cashier (type) {
      this.navIndex = type
      sessionStorage.setItem('navIndex', type)
      if (type === 0) {
        this.$router.push('/')
      } else {
        this.$router.push('/reportFrom')
      }
    },
    // 退出登录
    loginOut () {
      window.localStorage.clear()
      sessionStorage.clear()
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="less">
  body{
    padding: 0;
    margin: 0;
    background: #F7F8FA;
  }
  .header{
    width: 100%;
    height: 56rem;
    position: fixed;
    top: 0;
    background: #00281C;
    display: flex;
    align-items: center;
    z-index: 999;
    .nav{
      display: flex;
      align-items: center;
      margin-left: 30rem;
      flex: 1;
      .nav-list{
        width: 76rem;
        height: 32rem;
        background: #08382A;
        border-radius: 2rem;
        text-align: center;
        line-height: 32rem;
        font-size: 16rem;
        color: #fff;
        margin-right: 15rem;
        cursor: pointer;
      }
       .cashierActivity{
        background: #fff;
        color: #00281C;
      }
    }
    .loginOut{
      width: 76rem;
      height: 32rem;
      background: #08382A;
      border-radius: 2rem;
      text-align: center;
      line-height: 32rem;
      font-size: 16rem;
      color: #fff;
      margin-right: 30rem;
    }
    .header-photo{
      width: 38rem;
      height: 38rem;
      background: #eee;
      margin: 0 8rem 0 17rem;
      border-radius: 50%;
    }
    .header-name{
      color: #fff;
      font-size: 16rem;
      margin-left: 8rem;
      margin-right: 30rem;
    }
  }
</style>
