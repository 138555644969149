import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home')
  },
  {
    path: '/reportFrom',
    name: 'reportFrom',
    component: () => import('@/views/reportFrom')
  },
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/print',
    name: 'print',
    component: () => import('@/views/print')
  },
  {
    path: '/scanCodePage',
    name: 'scanCodePage',
    component: () => import('@/views/scanCodePage')
  },
  {
    path: '/scan',
    name: 'scan',
    component: () => import('@/views/scan')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
