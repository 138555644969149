import axios from './http'
import QS from 'qs'
import base from './base'
/**
 * get方法，对应get请求
 * @desc登录请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
// 账号密码登录
export function login (data) {
  return axios({
    url: `${base.url}/login`,
    method: 'post',
    data: QS.stringify(data)
  })
}
// 发送验证码
export function smscheckcode (phone) {
  return axios({
    url: `${base.url}/api/smscheckcode?phone=` + phone,
    method: 'post'
  })
}
// 手机号验证码登录
export function checkCodeLogin (checkCode, username) {
  return axios({
    url: `${base.url}/login/checkCodeLogin?checkCode=` + checkCode + '&username=' + username,
    method: 'post'
  })
}
// 校验手机号验证码
export function checkCode (checkCode, phone) {
  return axios({
    url: `${base.url}/api/smscheckcode/verifyCheckCode?checkcode=` + checkCode + '&phone=' + phone,
    method: 'post'
  })
}
// 修改密码
export function updatePwd (confirmPassword, password, phone) {
  return axios({
    url: `${base.url}/api/sysUser/updatePwd?confirmPassword=` + confirmPassword + '&password=' + password + '&phone=' + phone,
    method: 'post'
  })
}
// 根据会员编号/手机号查询会员信息
export function memberDetail (memberCode, phone) {
  return axios({
    url: `${base.url}/api/member?memberCode=` + memberCode + '&phone=' + phone,
    method: 'get'
  })
}
// 新增会员
export function addMember (data) {
  return axios({
    url: `${base.url}/api/member`,
    method: 'post',
    data: QS.stringify(data)
  })
}
// 查询会员收货地址
export function checkAddress (memberId) {
  return axios({
    url: `${base.url}/api/memberAddress?memberId=` + memberId,
    method: 'get'
  })
}
// 编辑收货地址
export function defaultAddress (data) {
  return axios({
    url: `${base.url}/api/memberAddress`,
    method: 'put',
    data: data
  })
}
// 新增收货地址
export function addAddress (data) {
  return axios({
    url: `${base.url}/api/memberAddress`,
    method: 'post',
    data: data
  })
}
// 删除收货地址
export function deletememberAddress (id) {
  return axios({
    url: `${base.url}/api/memberAddress?id=` + id,
    method: 'delete'
  })
}
// 查询门店导购
export function findByStoreId () {
  return axios({
    url: `${base.url}/api/sysUser/findByStoreId`,
    method: 'get'
  })
}
// 查询商品
export function getBySpecNo (specNo) {
  return axios({
    url: `${base.url}/api/goodsSpec/getBySpecNo?specNo=` + specNo,
    method: 'get'
  })
}
// 查询门店留言
export function getMessage () {
  return axios({
    url: `${base.url}/api/storeMessage`,
    method: 'get'
  })
}
// 新增门店留言
export function addMessage (data) {
  return axios({
    url: `${base.url}/api/storeMessage`,
    method: 'post',
    data: data
  })
}
// 修改门店留言
export function editMessage (data) {
  return axios({
    url: `${base.url}/api/storeMessage`,
    method: 'put',
    data: data
  })
}
// 创建订单
export function addOrder (data) {
  return axios({
    url: `${base.url}/api/orders`,
    method: 'post',
    data: data
  })
}
// 查询挂单列表
export function hangingList (phone) {
  return axios({
    url: `${base.url}/api/orders?memberPhone=` + phone,
    method: 'get'
  })
}
// 新增订单详情
export function addOrderDetail (data) {
  return axios({
    url: `${base.url}/api/orderDetail`,
    method: 'post',
    data: data
  })
}
// 修改订单详情
export function editOrderDetail (data) {
  return axios({
    url: `${base.url}/api/orderDetail`,
    method: 'put',
    data: data
  })
}
// 删除订单商品
export function delelteOrderDetail (orderId, specId) {
  return axios({
    url: `${base.url}/api/orderDetail?orderId=` + orderId + '&specId=' + specId,
    method: 'delete'
  })
}
// 删除订单
export function delelteOrder (orderId) {
  return axios({
    url: `${base.url}/api/orders/deleteHangOrder?orderId=` + orderId,
    method: 'delete'
  })
}
// 取单
export function findByOrderId (orderId) {
  return axios({
    url: `${base.url}/api/orders/findByOrderId?orderId=` + orderId,
    method: 'get'
  })
}
// 新增订单导购
export function addorderGuide (data) {
  return axios({
    url: `${base.url}/api/orderGuide`,
    method: 'post',
    data: data
  })
}
// 更新订单
export function updateOrders (data) {
  return axios({
    url: `${base.url}/api/orders/updateOrders`,
    method: 'put',
    data: data
  })
}
// 查询可代寄代发门店
export function findDeliverGoodsStock (orderId, storeType) {
  return axios({
    url: `${base.url}/api/goodsStock/findDeliverGoodsStock?orderId=` + orderId + '&storeType=' + storeType,
    method: 'get'
  })
}
// 查询指定门店的商品库存
export function findStoreGoodsStock (erpShopId, orderId) {
  return axios({
    url: `${base.url}/api/goodsStock/findStoreGoodsStock?erpShopId=` + erpShopId + '&orderId=' + orderId,
    method: 'get'
  })
}
// 订单支付
export function orderPay (data) {
  return axios({
    url: `${base.url}/api/orders/orderPay`,
    method: 'put',
    data: data
  })
}
// 获取地区信息
export function queryCityTree () {
  return axios({
    url: `${base.url}/api/sysCity/queryCityTree`,
    method: 'get'
  })
}
// 查询用户信息
export function findByUserId () {
  return axios({
    url: `${base.url}/api/sysUser/findByUserId`,
    method: 'get'
  })
}
// 查找入会门店列表
export function sysStore () {
  return axios({
    url: `${base.url}/api/sysStore`,
    method: 'get'
  })
}
// 查询门店报表
export function infoStatistics () {
  return axios({
    url: `${base.url}/api/infoStatistics`,
    method: 'get'
  })
}
// 查看折扣比例
export function discountRatio () {
  return axios({
    url: `${base.url}/api/discountRatio`,
    method: 'get'
  })
}
